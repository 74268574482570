<template>
  <div>
    <div class="row justify-content-end">
      <div class="col-auto">
        <button @click="refresh()" class="btn btn-info">
          <i class="bi bi-arrow-clockwise"></i>
          Actualiser
        </button>
      </div>

      <div class="col-auto">
        <button
          @click="$router.push({ name: 'checks-suppliers-create' })"
          class="btn btn-info"
        >
          <i class="bi bi-arrow-clockwise"></i>
          Créér Chéque Fournisseur
        </button>
      </div>
    </div>
    <br />
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Nº Chéque</th>
          <th scope="col">Nom Complet</th>
          <th scope="col">C / F</th>
          <th scope="col">Etat</th>
          <th scope="col">Date</th>
          <th scope="col">Nº Facture</th>
          <th scope="col">Montant</th>
          <th scope="col">Créer par</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(check, index) in checks.filter((p) => p.supplier)"
          :key="index++"
        >
          <td>
            {{ index }}
          </td>
          <td>
            {{ check.checkNumber }}
          </td>
          <td>
            <span v-if="check.customer">
              {{ check.customer.fullName }}
            </span>

            <span v-else-if="check.supplier">
              {{ check.supplier.fullName }}
            </span>
          </td>

          <td>
            <span v-if="check.customer" class="text-success"> Client </span>

            <span v-else-if="check.supplier" class="text-danger">
              Fournisseur
            </span>
          </td>
          <td>
            <span class="bg-warning rounded px-2 py-1">
              {{ check.status }}
            </span>
          </td>
          <td>{{ check.date }}</td>
          <td class="er">
            <span v-if="check.sale">
              {{ check.sale.reference }}
            </span>
            <span v-else-if="check.purchase">
              {{ check.purchase.reference }}
            </span>
          </td>

          <td class="text-success">
            <span v-if="check.amount >= 0">
              {{ check.amount }}
            </span>
          </td>

          <td>
            <!-- {{ check.user_reference }}
            <br /> -->
            <span v-if="check.user">
              {{ check.user.name }}
            </span>
          </td>
          <td class="p-0 m-0">
            <button
              @click="
                $router.push({
                  name: 'checks-suppliers-details',
                  params: { reference: check.reference },
                })
              "
              class="btn"
            >
              <i class="bi bi-info-square fs-5"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      search_value: "",
      addedToday: false,
      sortValue: "",
    };
  },
  computed: {
    ...mapGetters("check", {
      checks: "getAll",
    }),
  },
  mounted() {
    this.$store.dispatch("check/getAll");
  },
  methods: {
    async search(value) {
      return await this.$store.commit("check/search", value);
    },
    async refresh() {
      return await this.$store.dispatch("check/getAll");
    },

    async listChecks(checkbox) {
      if (checkbox == true) {
        console.log(checkbox);
        return await this.$store.commit("check/getTodays");
      } else {
        return await this.$store.dispatch("check/getChecks");
      }
    },
    async sort(value) {
      return await this.$store.commit("check/sort", value);
    },
  },
};
</script>
